
import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { StorageService } from '../../services/storage.service';
import { ExerciseUserRole, IExerciseUser } from "src/app/model/exercise.model";


@Component({
  selector: 'user-table-row',
  template: `
    <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                <mat-select [(value)]='uRoleSelected'  (selectionChange)='onURoleChanged($event)' 
                class="font-main">
                  <mat-option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                    {{uRole}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
          <!-- <select [value]='uRoleSelected'  class="font-dark select-cg"  (change)="onURoleChanged($event)" [disabled]="inputDisabled">
                    <option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                      {{uRole}}
                    </option>
                  </select> -->
  `,
   styleUrls: ['./exercise-users-view.component.scss']
})
export class UserTableRowComponent implements OnInit, OnDestroy {
 @Input() user!: IExerciseUser
 uRoleSelected: any
  constructor(public storage:StorageService) {}
  inputDisabled = false

  exerciseUserRoles: ExerciseUserRole[] = [
    ExerciseUserRole.ADMIN,
    ExerciseUserRole.EXCON,
    ExerciseUserRole.OBSERVER,
    ExerciseUserRole.TRAINER,
  ]
  ngOnDestroy(): void {
   
  }
  ngOnInit(): void {
    
  }

  onURoleChanged(e:any) {
    if(this.inputDisabled) { return }
    console.log(e);
    this.uRoleSelected = e.value;//.toString()
    
  }

 
}