import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { ExerciseUserRole } from "src/app/model/exercise.model";
import { ExerciseDashboardNavIcon, NAV_EVENT } from "src/app/model/models";

// import { ApiService } from "src/app/services/api.service";
// import { StorageService } from "src/app/services/storage.service";
// import { UiService } from "src/app/services/ui.service";

@Component({
  selector: "dashboard-nav-bar",
  template: `
    <div class="d-flex flex-wrap">
      <div
        *ngFor="let navItem of navItems; let i = index"
        [class.ml-auto]="i == 0 && !leftAlign"
      >
        <app-dashboard-navbar-icon-button [model]="navItem">
        </app-dashboard-navbar-icon-button>
      </div>
    </div>
  `,
})
export class ExerciseDashboardNavBar {
  @Input() showLogout: boolean = true;
  @Input() leftAlign!: boolean;

  navItems: ExerciseDashboardNavIcon[] = [
    {
      icon: "apps",
      event: NAV_EVENT.GOTO_MAIN,
      tooltip: "MAIN MENU",
      access: [ExerciseUserRole.ALL],
    },

    {
      icon: "schedule",
      event: NAV_EVENT.SHOW_DURATION,
      tooltip: "TIME / DURATION",
      access: [ExerciseUserRole.ADMIN],
    },

    {
      icon: "poll",
      event: NAV_EVENT.SHOW_TEAM_PERFORMANCE,
      tooltip: "TEAM PERFORMANCE",
      access: [
        ExerciseUserRole.TRAINER,
        ExerciseUserRole.ADMIN,
        ExerciseUserRole.EXCON,
      ],
    },

    {
      icon: "speed",
      event: NAV_EVENT.SHOW_PERFORMANCE,
      tooltip: "PERFORMANCE",
      access: [
        ExerciseUserRole.TRAINER,
        ExerciseUserRole.ADMIN,
        ExerciseUserRole.EXCON,
      ],
    },

    {
      icon: "web_stories",
      event: NAV_EVENT.SHOW_LOGBOOK,
      tooltip: "LOGBOOK",
      access: [ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON],
    },
    {
      icon: "speaker_notes",
      event: NAV_EVENT.COMMENT_OVERVIEW,
      tooltip: "Comments overview",
      access: [ExerciseUserRole.ADMIN, ExerciseUserRole.EXCON],
    },

    {
      icon: "groups",
      event: NAV_EVENT.SHOW_USER_GROUPS,
      tooltip: "USER GROUPS",
      access: [ExerciseUserRole.ADMIN],
    },

    {
      icon: "location_on",
      event: NAV_EVENT.SHOW_LOCATIONS,
      tooltip: "Location details",
      access: [ExerciseUserRole.ADMIN],
    },

    {
      icon: "map",
      event: NAV_EVENT.TOGGLE_MAP,
      tooltip: "SHOW MAP###SHOW TIMELINE",
      access: [
        ExerciseUserRole.ADMIN,
        ExerciseUserRole.EXCON,
        ExerciseUserRole.TRAINER,
        ExerciseUserRole.OBSERVER,
      ],
      isToggle: true,
      isActive: false,
    },

    {
      icon: "ios_share",
      event: NAV_EVENT.SHOW_FULL_REPORT,
      tooltip: "EXPORT",
      access: [ExerciseUserRole.ADMIN],
    },
    {
      icon: "invert_colors",
      event: NAV_EVENT.DARKMODE,
      tooltip: "Invert Colors",
      access: [ExerciseUserRole.ADMIN],
    },

  
  ];

  NAV_EVENTS = NAV_EVENT;
  @Output() onNavEvent: EventEmitter<NAV_EVENT> = new EventEmitter<NAV_EVENT>();

  // access(roles:string[]):boolean{
  //   return true
  // }

  // constructor(
  //   public api:ApiService,
  //   public storage:StorageService,
  //   public ui:UiService)
  //   { }
}
