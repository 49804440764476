import { Injectable } from "@angular/core";
// import * as ExcelJS from 'exceljs';
import writeXlsxFile from "write-excel-file";
import { injectListSchema } from "./utils/schema/injectList.schema";
import { logbookSchema } from "./utils/schema/logbook.schema";
import { reportCommentSchema } from "./utils/schema/reportsComment.schema";
import { AssignedInjectListSchema } from "./utils/schema/assignedInjectList.schema";

// import { saveAs } from 'file-saver';
// import { FileSaverService } from 'ngx-filesaver';
export type student = {
  name: string;
  dateOfBirth: Date;
  cost: number;
  paid: boolean;
};
@Injectable({
  providedIn: "root",
})
export class ExcelService {
  constructor() {}
   _fileName =  `Export-${new Date().getFullYear()}-${new Date().getMonth()}-${new Date().getDate()}`
  async generateList(
    assignedInjects: any[],
    injects: any[],
    ratingItems: any[],
    logbookItems: any[]
  ) {
    await writeXlsxFile([injects, assignedInjects, ratingItems, logbookItems], {
      schema: [
        injectListSchema,
        AssignedInjectListSchema,
        reportCommentSchema,
        logbookSchema,
      ],
      headerStyle: {
        backgroundColor: "#eeeeee",
        fontWeight: "bold",
        align: "center",
        alignVertical: "top",
      },

      // schema: [schema1, schema2],
      sheets: ["Inject List", "Assigned Injects", "Ratings", "Logbook"],
      //Inject List, Assigned Injects
      fileName: `${this._fileName}.xlsx`,
    });
  }
}

export type InjectExportAssignedInject = {
  inject_no: string;
  short_title: string;
  team: string;
  deliveryMethod: string;
  description: string;
  related_documents: string;
  related_equipment: string;
  role_players_instruction: string;
  on_scene_preparation: string;
  additional_guidance_caution_options: string;
  expected_outcomes: string;
  trainers_action_requirement: string;
};

export type InjectExportInject = {
  inject_no: string;
  short_title: string;
  deliveryMethod: string;
  description: string;
  related_documents: string;
  role_players_instruction: string;
  on_scene_preparation: string;
  additional_guidance_caution_options: string;
  expected_outcomes: string;
  trainers_action_requirement: string;
};

export type RatingExportItem = {
  inject_no: string;
  createdAt: string;
  comment: string;
  itemType: string;
  userName: string;
  vote: string;
};

// const HEADER_ROW = [
//   {
//     value: 'Name',
//     fontWeight: 'bold',

//   },
//   {
//     value: 'Date of Birth',
//     fontWeight: 'bold',

//   },
//   {
//     value: 'Cost',
//     fontWeight: 'bold'
//   },
//   {
//     value: 'Paid',
//     fontWeight: 'bold'
//   }
// ]

// const DATA_ROW_1 = [
//   // "Name"
//   {
//     type: String,
//     value: 'John Smith'
//   },

//   // "Date of Birth"
//   {
//     type: Date,
//     value: new Date(),
//     format: 'mm/dd/yyyy'
//   },

//   // "Cost"
//   {
//     type: Number,
//     value: 1800
//   },

//   // "Paid"
//   {
//     type: Boolean,
//     value: true
//   }
// ]
// const DATA_ROW_2 = [
//   'John Smith',
//   new Date(),
//   1800,
//   true,

// ]
// const data:any = [
//   HEADER_ROW,
//   DATA_ROW_1,
//   DATA_ROW_2
// ]
// const workbook = new ExcelJS.Workbook();
// const worksheet = workbook.addWorksheet('Sheet 1');
// // Add headers
// const headers = Object.keys(data[0]);
// worksheet.addRow(headers);
// // Add data
// data.forEach((item) => {
//   const row:any[] = [];
//   headers.forEach((header) => {
//     row.push(item[header]);
//   });
//   worksheet.addRow(row);
// });
// // Save the workbook to a blob
// workbook.xlsx.writeBuffer().then((buffer) => {
//   const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
//   saveAs(blob, `${fileName}.xlsx`);
// });
// const columns = [
//   {},

//   { width: 50 }, // in characters
//   { width: 20 }, // in characters
//   {}
// ]
// await writeXlsxFile(data, {
//   columns, // (optional) column widths, etc.

//   fileName: 'file.xlsx'
// })
// await writeXlsxFile(objects, {

//   schema,
//   fileName: 'file.xlsx'
// })

// async generateExcel(fileName: string) {
//   // data: any[],
//   const objects: any[] = [
//     {
//       name: "John Smith",
//       dateOfBirth: new Date(),
//       cost: 1800,
//       paid: true,
//     },
//     {
//       name: "Alice Brown",
//       dateOfBirth: new Date(),
//       cost: 2600,
//       paid: false,
//     },
//   ];
//   const schema1 = [
//     {
//       column: "Name",
//       type: String,
//       width: 50,
//       value: (student: { name: string }) => student.name,
//     },
//     {
//       column: "Date of Birth",
//       type: Date,
//       format: "mm/dd/yyyy",
//       value: (student: { dateOfBirth: Date }) => student.dateOfBirth,
//     },
//     {
//       column: "Cost",
//       type: Number,
//       format: "#,##0.00",
//       with: 49,
//       value: (student: { cost: number }) => student.cost,
//     },
//     {
//       column: "Paid",
//       type: Boolean,
//       value: (student: { paid: boolean }) => student.paid,
//     },
//   ];
//   const schema2 = [
//     {
//       column: "Name",
//       type: String,
//       width: 50,
//       value: (student: { name: string }) => student.name,
//     },
//     {
//       column: "Date of Birth",
//       type: Date,
//       format: "mm/dd/yyyy",
//       value: (student: { dateOfBirth: Date }) => student.dateOfBirth,
//     },
//     {
//       column: "Cost",
//       type: Number,
//       format: "#,##0.00",
//       with: 49,
//       value: (student: { cost: number }) => student.cost,
//     },
//     {
//       column: "Paid",
//       type: Boolean,
//       value: (student: { paid: boolean }) => student.paid,
//     },
//   ];

//   await writeXlsxFile([objects, objects], {
//     schema: [schema1, schema2],
//     // schema: [schema1, schema2],
//     sheets: ["In 1", "List 2"],
//     //Inject List, Assigned Injects
//     fileName: "file.xlsx",
//   });
// }
