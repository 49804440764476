
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid bgDarg " style="height: calc(100% - 88px);">

  <div class="container-fluid d-flex ">

    <div class="p-2 w50p d-flex flex-column">

      <div class="p-0">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
          <mat-select [(ngModel)]='selectedTimelineItem' (ngModelChange)='onChanged($event)' class="font-main">
          <!-- <mat-select [(value)]='selectedTimelineItem'  (selectionChange)='onChanged($event)'  class="font-main"> -->
            <mat-option  value="all">
              ALL TEAMS
              </mat-option>
            <mat-option *ngFor="let timelineItem of timelineItems" [value]="timelineItem">
            {{timelineItem.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      
      <div class="p-0">
        <!-- style="width: 700px;" -->
        
          <mat-card class="dashboard-main-card-background mt1 pt10 pb10 pr10 pl10 ">
            <div class="d-flex">
              <div class="p-2 txtLightblue">
                EXERCISE PERFORMANCE - BY TEAM 
              </div>
              <!-- <div class="p-0 ml-auto">
                <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                  <mat-select [(value)]='selectedTeam'  (selectionChange)='teamSelectionChanged($event)' class="font-main">
                    <mat-option value="allTeams">ALL TEAMS</mat-option>
                    <mat-option *ngFor="let team of teams" [value]="team">
                      {{team.teamName}}
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div> -->
            </div>
          </mat-card>
          
          <mat-card class="dashboard-main-card-background mt1 pr0 pl0">
          
            <div class="container-fluid p0 pr5">
              <div class="d-flex flex-column txtLightblue">
                <div class="p-0">
                  <app-divider></app-divider>
               <!--    <div class="d-flex p0 list-box-trenner-row">
                    <div class="p-2 p0 pl0 list-box-trenner-col">
                      <div class="list-box-bg-rect-mini"></div>
                    </div>
                    <div class="p-2 p0  pr0 ml-auto list-box-trenner-col">
                      <div class="list-box-bg-rect-mini"></div>
                    </div>
                  </div> -->
                </div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">NUMBER OF INJECTS</div>
                    <div class="p-2 ml-auto fs18">{{number_of_injects}}</div>
                  </div>
                </div>
                <div class="p-0"><app-divider></app-divider></div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">NUMBER OF OBJECTIVES</div>
                    <div class="p-2 ml-auto fs18">{{number_of_objectives}}</div>
                  </div>
                </div>
                <!-- <div class="p-0"><app-divider></app-divider></div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">NUMBER OF TRAINER</div>
                    <div class="p-2 ml-auto fs18">{{number_of_trainers}}</div>
                  </div>
                </div>
                <div class="p-0"><app-divider></app-divider></div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">NUMBER OF ECT USERS</div>
                    <div class="p-2 ml-auto fs18">{{number_of_ect_users}}</div>
                  </div>
                </div> -->
                <div class="p-0"><app-divider></app-divider></div>
                <div class="d-flex">
                    <div class="p-2">
                    <div class="p-2">INJECT STATUS SUMMARY</div>
                    
                  </div>
                </div>
              </div>
      
              
      
                <div class="d-flex listitem-copy p0"  *ngFor='let injectItem of injectStatusList' style="height: 30px;">
                  <div class="p-0 uc pl10" style="width: 120px;" [ngStyle]="{'color':injectItem.status.color}">{{injectItem.status.title}}</div>
                  <div class="p-0 uc pr10"  style="width: 20px;text-align: right!important;">
                    {{injectItem.injects.length}}
                  </div>
                 
                  <div class="p-0 ml-auto uc pr10" style="width: 420px; ">
                    <performance-progress-inject [percent]='injectItem.percent' [color]='injectItem.status.color'></performance-progress-inject>
                  </div>
                </div>
      
                <div class="d-flex">
                  <div class="p-2">
                  <div class="p-2">OBJECTIVES FEEDBACK DETAILS</div>
                </div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">Rated</div>
                    <div class="p-2 ml-auto">{{number_of_objects_rated}}</div>
                  </div>
                </div>
                <div class="p-2">
                  <div class="d-flex">
                    <div class="p-2">COMMENTS</div>
                    <div class="p-2 ml-auto">{{number_of_ect_comment}}</div>
                  </div>
                </div>
              </div>
      
          
            </div>
          
          </mat-card>
        
      </div>
      <!-- <div class="p-2" *ngIf="selectedTimelineItem">
        selected: {{selectedTimelineItem.title}}
      </div> -->
  
      <!-- <div class="p-2 w33p ml-auto">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
          <mat-select [(ngModel)]='selectedTimelineItem' (ngModelChange)='onChanged($event)' class="font-main">
          <mat-select [(value)]='selectedTimelineItem'  (selectionChange)='onChanged($event)'  class="font-main">
            <mat-option  value="all">
              ALL TEAMS
              </mat-option>
            <mat-option *ngFor="let timelineItem of timelineItems" [value]="timelineItem">
            {{timelineItem.title}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div> -->

    </div>


    <!-- RIGHT COL -->
    <div class="p-2 w50p  d-flex flex-column">

      <div class="p-0">
        <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
          <mat-select [(ngModel)]='selectedExerciseDay' (ngModelChange)='onDayChanged($event)' class="font-main">
            <mat-option  value="all">
              ALL DAYS
              </mat-option>
            <mat-option *ngFor="let day of exerciseDays" [value]="day">
            {{day.dateString}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>



      <div class="p-0" *ngIf="selectedExerciseDay">
        <mat-card class="dashboard-main-card-background mt1 pt10 pb10 pr10 pl10 ">
          <div class="d-flex">
            <div class="p-2 txtLightblue">
              <!-- EXERCISE PERFORMANCE - BY TEAM  -->
               DAY???
            </div>
    
          </div>
        </mat-card>
        
        <mat-card class="dashboard-main-card-background mt1 pr0 pl0">
        
          <div class="container-fluid p0 pr5">
            <div class="d-flex flex-column txtLightblue">
              <div class="p-0">
                <app-divider></app-divider>
         
              </div>
              <div class="p-2">
                <div class="d-flex">
                  <div class="p-2">NUMBER OF INJECTS</div>
                  <div class="p-2 ml-auto fs18">{{number_of_injects_2}}</div>
                </div>
              </div>
              <div class="p-0"><app-divider></app-divider></div>
              <div class="p-2">
                <div class="d-flex">
                  <div class="p-2">NUMBER OF OBJECTIVES</div>
                  <div class="p-2 ml-auto fs18">{{number_of_objectives_2}}</div>
                </div>
              </div>
             
              <div class="p-0"><app-divider></app-divider></div>
              <div class="d-flex">
                  <div class="p-2">
                  <div class="p-2">INJECT STATUS SUMMARY</div>
                  
                </div>
              </div>
            </div>
    
              <div class="d-flex listitem-copy p0"  *ngFor='let injectItem of injectStatusList2' style="height: 30px;">
                <div class="p-0 uc pl10" style="width: 120px;" [ngStyle]="{'color':injectItem.status.color}">{{injectItem.status.title}}</div>
                <div class="p-0 uc pr10"  style="width: 20px;text-align: right!important;">
                  {{injectItem.injects.length}}
                </div>
               
                <div class="p-0 ml-auto uc pr10" style="width: 420px; ">
                  <performance-progress-inject [percent]='injectItem.percent' [color]='injectItem.status.color'></performance-progress-inject>
                </div>
              </div>
    
              <div class="d-flex">
                <div class="p-2">
                <div class="p-2">OBJECTIVES FEEDBACK DETAILS</div>
              </div>
              <div class="p-2">
                <div class="d-flex">
                  <div class="p-2">Rated</div>
                  <div class="p-2 ml-auto">{{number_of_objects_rated_2}}</div>
                </div>
              </div>
              <div class="p-2">
                <div class="d-flex">
                  <div class="p-2">COMMENTS</div>
                  <div class="p-2 ml-auto">{{number_of_ect_comment_2}}</div>
                </div>
              </div>
            </div>
    
        
          </div>
        
        </mat-card>

      </div>


    </div>
    
  
    
  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <!-- <ect-color-btn class="mr5" title="Save" icon="save" color="primary" [disabled]="saveDisabled" (clicked)="save()" ></ect-color-btn> -->
</mat-toolbar>
