import { TrainerFeedbackType, TrainerFeedbackVoteType } from "src/app/model/trainerFeedback.model";
import * as _ from 'lodash'
import { InjectExportAssignedInject } from "../excel.service";
import { IExerciseTimelineInjectRef } from "src/app/model/exercise.model";

function getTTDate(a?: number) : Date {
  return a ? new Date(a) : new Date();
}

export default getTTDate;


export function DATE_TO_STRING() : string {
  let date = new Date()
  let MM:number = date.getMonth() + 1;
  let DAY:number = date.getDate();

  let _d:string = DAY < 10 ? '0'+DAY.toString() : DAY.toString();
  let _m:string = MM < 10 ? '0'+MM.toString() : MM.toString();
  
  let _h = date.getHours()
  let _min = date.getMinutes()
   
    return `${_d}.${_m}.${date.getFullYear()} ${_h}:${_min}`; 
}

export function wait(duration: number, callback: () => void): void {
  setTimeout(() => {
    // console.warn('done ...');
    callback();
  },duration)
}
export const  texteditor_cleanup: object = {
  prompt: false,
  plainText: false,
  keepFormat: false,
  deniedTags: ['a', 'span','p',  'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6'],
  deniedAttrs: ['class', 'title', 'id', 'dir', 'style'],
  allowedStyleProps: []
};
export const toolbar_tools_NACHBEHANDLUNG: object = {
  /* ,'SourceCode' */
  items:[ 'Undo', 'Redo', 'Bold', 'Italic', 'UnorderedList', '|', 'CreateLink']
  // ,  'ClearFormat', 'SourceCode'
};

export function trainerFeedbackTypeToString(type: TrainerFeedbackType): string {
  return _.replace(type.toString(), '_', ' ')
  
}
export function trainerFeedbackTypeFromString(str: string): TrainerFeedbackType {
  return _.replace(str,' ', '_') as TrainerFeedbackType
}
export function trainerFeedbackVoteTypeToString(type: TrainerFeedbackVoteType): string {
  return _.replace(type.toString(), '_', ' ')
  
}
export function trainerFeedbackVoteTypeFromString(str: string): TrainerFeedbackVoteType {
  return _.replace(str,' ', '_') as TrainerFeedbackVoteType
}

export function convertInject(element: IExerciseTimelineInjectRef): InjectExportAssignedInject {
  // const regex = /<p>/gi;
  // console.log(paragraph.replaceAll(regex, 'ferret'));
  
  
  return {
    inject_no: element.inject_no!,
    short_title: element.short_title!,
    team: element.timelineItem!.title,
    deliveryMethod: element.deliveryMethod,
    description: element.description ? stripHtmlTags(element.description) : '',
    //
    // description: element.description ? element.description.replace(/<[^>]*>,&nbsp;/g, '') : '',
    related_documents: element.related_documents ? stripHtmlTags(element.related_documents) : '',
    related_equipment: element.related_equipment ? stripHtmlTags(element.related_equipment) : '',
    role_players_instruction: element.role_players_instruction ? stripHtmlTags(element.role_players_instruction) : '',
    on_scene_preparation: element.on_scene_preparation ? stripHtmlTags(element.on_scene_preparation) : '',
    additional_guidance_caution_options: element.additional_guidance_caution_options ? stripHtmlTags(element.additional_guidance_caution_options) : '',
    expected_outcomes: element.expected_outcomes ? stripHtmlTags(element.expected_outcomes) : '',
    trainers_action_requirement: element.trainers_action_requirement ? stripHtmlTags(element.trainers_action_requirement) : '',
  }
}

//const stripHTMLTags = str => str.replace(/<[^>]*>/g, '');

export function stripHtmlTags(str: string): string {
  // .replaceAll("<br>" , " CHAR(10)")
  return str.replace(/(<([^>]+)>)/gi, "").replaceAll("&nbsp;" , " ").replaceAll("&amp;" , "&")
}
