import { Component, inject, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { OVERLAY_TYPE } from "src/app/model/enums";
import { ExerciseDashboardNavIcon, NAV_EVENT } from "src/app/model/models";
import { StorageService } from "src/app/services/storage.service";
import { UiService } from "src/app/services/ui.service";

@Component({
  selector: "app-dashboard-navbar-icon-button",
  templateUrl: "./dashboard-navbar-icon-buton.component.html",
  styleUrls: ["./dashboard-navbar-icon-buton.component.scss"],
})
export class DashboardNavbarIconButonComponent implements OnInit {
  public ui = inject(UiService);
  public storage = inject(StorageService);
  // isActive: boolean = false
  isToggle: boolean = false;
  private router = inject(Router);

  @Input() model!: ExerciseDashboardNavIcon;

  ngOnInit(): void {
    this.isToggle = this.model.isToggle ?? false;
  }
  get isActive(): boolean {
    if (!this.isToggle) {
      return false;
    }
    return this.ui.isMapVisible;
  }
  get tooltip(): string {
    if (!this.isToggle) {
      return this.model.tooltip;
    }
    const tts = this.model.tooltip.split("###");
    return tts.length > 1
      ? this.isActive
        ? tts[1]
        : tts[0]
      : this.model.tooltip;
  }
  tapped() {
    // this.onNavEvent.emit(e);
    switch (this.model.event) {
      case NAV_EVENT.TOGGLE_SIDE_BAR:
        this.ui.isMainToolbarOut = !this.ui.isMainToolbarOut;
        setTimeout(() => {
          this.ui.windowResize.emit(this.ui.currentScreenSize);
        }, 150);
        return;

      case NAV_EVENT.GOTO_MAIN:
        this.router.navigate(["exercise-overview"]);
        return;
      case NAV_EVENT.SHOW_DURATION:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.SHOW_DURATION);
        return;
      case NAV_EVENT.SHOW_TEAM_PERFORMANCE:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.TEAM_PERFORMANCE);
        return;
      case NAV_EVENT.SHOW_PERFORMANCE:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.PERFORMANCE);
        return;
      case NAV_EVENT.SHOW_LOGBOOK:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.LOGBOOK);
        return;
      case NAV_EVENT.SHOW_USER_GROUPS:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.USERGROUPS);
        return;

      case NAV_EVENT.COMMENT_OVERVIEW:
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.COMMENT_OVERVIEW);
        return;
      // case NAV_EVENT.SIGN_OUT:
      // return
      // case NAV_EVENT.SHOW_ADMIN_PANEL:
      //   this.router.navigateByUrl('admin')
      // return
      case NAV_EVENT.SHOW_LOCATIONS:
        console.log(this.storage.currentExer?.getLocations());
        // this.ui.openExerciseLocationsOverlay()
        this.ui.onOpenOverlayEvent.emit(OVERLAY_TYPE.LOCATIONS);

        return;

      case NAV_EVENT.DARKMODE:
        this.ui.toggleDarkMode();
        return;
      case NAV_EVENT.TOGGLE_MAP:
        this.ui.toogleMapView();
        return;
      case NAV_EVENT.SHOW_FULL_REPORT:
        this.storage.exportToExcel();
        // this.ui.toogleMapView();
        return;
    }
  }
}
