
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from "@angular/core";

import { StorageService } from '../../services/storage.service';
import { ExerciseUserRole, IExerciseUser } from "src/app/model/exercise.model";
import { CompanyUser } from "src/app/model/company.model";



@Component({
  selector: 'company-user-table-row',
  template: `
  <div class="d-flex align-items-center" style="height: 40px; padding: .75rem; border-top: 1px solid rgba(255, 255, 255, 0.2); color: #54a3aa; font-size: 14px;   background: rgb(22, 23, 27);">
    <div class="p-0 " style="width: calc((100% - 380px) / 2);">{{user.name}}</div>
    <div class="p-0 borderRight" style="width: calc((100% - 380px) / 2);">{{user.email}}</div>
    <div class="p-0 borderRight ml-auto w120 tlc">{{user.userState}}</div>
    <div class="p-0 borderRight tlc w120">

    <div *ngIf="user.exerciseUserRole">{{user.exerciseUserRole}}</div>
    <div *ngIf="!user.exerciseUserRole">

        <mat-icon class='dxfx_icon_btn_mini_circ' [matMenuTriggerFor]="menu">person</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item *ngFor="let role of exerciseUserRoles" (click)="selectRole(role)">
                  <!-- <mat-icon>power_settings_new</mat-icon> -->
                  <span>{{role}}</span>
                </button>
                
              </mat-menu>
      </div>
      
    </div>
   
    <!-- <div class="p-0 borderRight w120 tlc">
      <div *ngIf="user.userRole">

        {{user.userRole}}
      </div>
      <div *ngIf="!user.userRole">

        <mat-icon class='dxfx_icon_btn_mini' [matMenuTriggerFor]="menu">more_vert</mat-icon>
              <mat-menu #menu="matMenu">
                <button mat-menu-item >
          
                  <mat-icon>power_settings_new</mat-icon>
                  <span>Logout</span>
                </button>
                <button mat-menu-item >
          
                  <mat-icon>admin_panel_settings</mat-icon>
                  <span>Admin View</span>
                </button>
              </mat-menu>
      </div>
    </div> -->
    <div class="p-0 w40 borderRight">
    <mat-icon *ngIf="user.userState"  class="dxfx_icon_btn_mini" >check</mat-icon>
    <mat-icon *ngIf="!user.userState && this.user.exerciseUserRole"  class="dxfx_icon_btn_mini_circ" [class.DISABLED]="inputDisabled" (click)="invite()">add</mat-icon>
    </div>

  </div>
   <!--  <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
                <mat-select [(value)]='uRoleSelected'  (selectionChange)='onURoleChanged($event)' 
                class="font-main">
                  <mat-option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                    {{uRole}}
                  </mat-option>
                </mat-select>
              </mat-form-field> -->
          <!-- <select [value]='uRoleSelected'  class="font-dark select-cg"  (change)="onURoleChanged($event)" [disabled]="inputDisabled">
                    <option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                      {{uRole}}
                    </option>
                  </select> -->
  `,
   styleUrls: ['./exercise-users-view.component.scss']
})
export class CompanyUserTableRowComponent implements OnInit, OnDestroy {
 @Input() user!: CompanyUser
 @Output() inviteCompanyUserForEx = new EventEmitter<CompanyUser>()
 uRoleSelected: any
  constructor(public storage:StorageService) {}
  inputDisabled = false

  exerciseUserRoles: ExerciseUserRole[] = [
    ExerciseUserRole.ADMIN,
    ExerciseUserRole.EXCON,
    ExerciseUserRole.OBSERVER,
    ExerciseUserRole.TRAINER,
  ]
  ngOnDestroy(): void {
   
  }
  ngOnInit(): void {
    
  }
  invite() {
    if(!this.user.exerciseUserRole) { return }
    this.inviteCompanyUserForEx.emit(this.user)
  }
  onURoleChanged(e:any) {
    if(this.inputDisabled) { return }
    console.log(e);
    this.uRoleSelected = e.value;//.toString()
    
  }
  selectRole(e: ExerciseUserRole) {
    this.user.exerciseUserRole = e;
  }
 
}
  // td {
  //   color: #54a3aa !important;
  //   font-size: 14px;
  //   font-weight: 400;
  //   background: rgb(22, 23, 27);
  // }