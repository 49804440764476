export enum VIEW_MODE {
  EDIT_VIEW = "EDIT_VIEW",
  TIMELINE_VIEW = "TIMELINE_VIEW",
  MAP_VIEW = "MAP_VIEW",
  LOADING = "LOADING",
  INITIALIZING = "INITIALIZING",
  EDIT_FADEOUT = "EDIT_FADEOUT",
  EDIT_FADEIN = "EDIT_FADEIN",
  TIMELINE_FADEOUT = "TIMELINE_FADEOUT",
  TIMELINE_FADEIN = "TIMELINE_FADEIN",
}

export enum InjectStates {
  REQUESTED = "REQUESTED",
  DRAFT = "DRAFT",

  CANCELED = "CANCELED",
  PLANNED = "PLANNED",
  PREPARED = "PREPARED",
  RUN = "RUN",
  DELIVERED = "DELIVERED",

  /* REQUESTED = 1,
  DRAFT = 2,
  PLANNED = 3,
  RUN = 4,
  PREPARED = 5,
  CANCELED = 6,
  DELIVERED = 7,
  1 = REQUESTED,
  2 = DRAFT,
  3 = CANCELED,
  4 = PLANNED,
  5 = PREPARED,
  6 = RUN,
  7 = DELIVERED, */
}

export enum UserGroups_VIEW_MODE {
  TIMELINE_LIST = "TIMELINE_LIST",
  TIMELINE_ADD = "TIMELINE_ADD",
  USER_ADD = "USER_ADD",
}

export enum ExerciseState {
  DRAFT = "DRAFT",
  SCHEDULED = "SCHEDULED",
  RUNNING = "RUNNING",
  DONE = "DONE",
}

export interface ISnackData {
  duration: number;
  panelClass: string;
  message: string;
}

export enum ALERT_BUTTON_COLOR_TYPE {
  WARN = "WARN",
  ACCENT = "ACCENT",
  PRIMARY = "PRIMARY",
  BASIC = "BASIC",
}

export enum ALERT_BUTTON_ACTION_TYPE {
  OK = "OK",
  CANCEL = "CANCEL",
  RESUME = "RESUME",
}
export enum HTTP_METHOD {
  DELETE = "DELETE",
  PATCH = "PATCH",
  POST = "POST",
  GET = "GET",
}

export enum BASE_VIEW_MODE {
  NONE = "NONE",
  ADD = "ADD",
  EDIT = "EDIT",
  OVERVIEW = "OVERVIEW",
  SHOWALL = "SHOWALL",
}

export enum OVERLAY_TYPE {
  USERGROUPS = "USERGROUPS",
  LOCATIONS = "LOCATIONS",
  SHOW_DURATION = "SHOW_DURATION",
  LOGBOOK = "LOGBOOK",
  PERFORMANCE = "PERFORMANCE",
  TEAM_PERFORMANCE = "TEAM_PERFORMANCE",
  COMMENT_OVERVIEW = "COMMENT_OVERVIEW",
}
export enum ExerciseUserOverlayMode {
  EDIT = 'EDIT',
  ADD = 'ADD'
}