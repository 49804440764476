import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CompanyUser } from 'src/app/model/company.model';
import { BASE_VIEW_MODE, ExerciseUserOverlayMode } from 'src/app/model/enums';
import {  ExerciseUserRole, IExerciseTimelineItem, IExerciseUser, IExerciseUserState } from 'src/app/model/exercise.model';
import { ExerciseUserOverlayComponent } from 'src/app/overlays/exercise-user-overlay/exercise-user-overlay.component';
import { ApiService } from 'src/app/services/api.service';
import { StorageService } from 'src/app/services/storage.service';
import { UiService } from 'src/app/services/ui.service';
import { wait } from 'src/app/services/utils/functions';

@Component({
  selector: 'app-exercise-users-view',
  templateUrl: './exercise-users-view.component.html',
  styleUrls: ['./exercise-users-view.component.scss']
})
export class ExerciseUsersViewComponent implements OnInit {
  uStates = IExerciseUserState
  uRoleSelected: ExerciseUserRole | undefined
  exerciseUserRoles: ExerciseUserRole[] = [
    ExerciseUserRole.ADMIN,
    ExerciseUserRole.EXCON,
    ExerciseUserRole.OBSERVER,
    ExerciseUserRole.TRAINER,
  ]

  viewMode: BASE_VIEW_MODE = BASE_VIEW_MODE.NONE
  BASE_VIEW_MODES = BASE_VIEW_MODE
  // isAddingMode = false

  sendInvite = false
  timelineName = ''
  addedEmail = ''
  addedUserName = ''

  @Input() exerciseId!: string;
  @Output() onCloseWithReload = new EventEmitter<void>();
  exerciseUser: IExerciseUser [] = []
  companyUsers: CompanyUser[] = []

  currentUserId: string | undefined
  constructor(
    public api: ApiService, 
    public ui: UiService, 
    private storage: StorageService,
    private dialog: MatDialog
  ) {
      
  }

  get inputDisabled() : boolean {
    return this.storage.isExerciseDeboarded
  }
  ngOnInit(): void {
    if (this.storage.currentUser) {
      this.currentUserId = this.storage.currentUser.uid
    }
    this.load()
    wait(2000, () => {
      console.log(this.storage.currentExer);
     
    })
  }

  
  async load() {
    const usrs = await this.api.apiGetP('user/exercise-user/'+ this.exerciseId)
    this.exerciseUser = usrs as  IExerciseUser[]
    this.viewMode = BASE_VIEW_MODE.OVERVIEW
  }

  addUser(roleItem:IExerciseTimelineItem) {
    if(this.inputDisabled) { return }
    this.addedUserName = ''
    this.addedEmail = ''
    this.uRoleSelected = roleItem.itemType as unknown as ExerciseUserRole
    this.viewMode = BASE_VIEW_MODE.ADD
  }

  changeView(v: BASE_VIEW_MODE) {
    if(this.inputDisabled) { return }
    this.editUserI = undefined
    this.viewMode = v;
    this.addedUserName = ''
    this.addedEmail = ''
    this.selectedUserName = ''
    this.selectedUserEmail = '';
  }

  onURoleChanged(e:any) {
    if(this.inputDisabled) { return }
    console.log(e.value);
    // if (this.viewMode === BASE_VIEW_MODE.ADD) {
    //   this.uRoleSelected = e.target.value;
    // } else if (this.viewMode === BASE_VIEW_MODE.EDIT) {
    //   this.selectedUserRole = e.value;//.toString()
    // }
    
  }

  async saveNewUser() {
    if(this.inputDisabled) { return }
    //return
    
    if(!this.emailError && !this.userNameError) {
      const dto = {
        exerciseId: this.exerciseId,
        email: this.addedEmail.trim().toLowerCase(),
        name: this.addedUserName,
        userRole: this.uRoleSelected
      }
      console.log(dto);
      await this.api.apiPost('user/draft-user-for-exercise-by-email', dto)
      this.onCloseWithReload.emit()
      setTimeout(()=>{
        this.changeView(BASE_VIEW_MODE.OVERVIEW)
        // this.addedUserName = ''
        // this.addedEmail = ''
        // this.viewMode = BASE_VIEW_MODE.OVERVIEW
        
      }, 150)
      setTimeout(()=>{
        
        this.load()
      }, 250)
    
    }
    
  }
  async updateUser() {
    if(!this.editUserI) { return}
    if(this.inputDisabled) { return }
    const dto = {
      
      userRole: this.selectedUserRole
    }
    console.log(dto);
    await this.api.apiPatch('user/change-role', this.editUserI.id.toString() ,dto)
    this.onCloseWithReload.emit()
    setTimeout(()=>{
      this.changeView(BASE_VIEW_MODE.OVERVIEW)
      // this.addedUserName = ''
      // this.addedEmail = ''
      // this.viewMode = BASE_VIEW_MODE.OVERVIEW
      
    }, 150)
    setTimeout(()=>{
      
      this.load()
    }, 250)
    
  }

  get userNameError(): boolean {
    if(this.addedUserName.length > 4) {
      return false
    }
    return true
  }

  get emailError(): boolean {
    if(this.addedEmail.length > 4 && this.addedEmail.includes('@') && this.addedEmail.includes('.')) {
      return false
    }
    return true
  }
  
  get saveBtnDisabled(): boolean {
    if(!this.uRoleSelected || this.emailError || this.userNameError) {
      return true
    } else {
      return false
    }
  }
  
  selectedUserName: string = ''
  selectedUserEmail: string = ''
  selectedUserRole: ExerciseUserRole | undefined //string = ''//
  editUserI: IExerciseUser | undefined
  editUser(u: IExerciseUser) {
    if(this.inputDisabled) { return }

    // this.dialog.open(ExerciseUserOverlayComponent, {
    //   data: {
    //     exerciseId: this.exerciseId,
    //     user: u,
    //     inputDisabled: this.inputDisabled,
    //     mode: ExerciseUserOverlayMode.EDIT
    //   },
    //   "width": "600px",

    // }
    // )

    this.editUserI = u
    this.selectedUserName = u.name;
    this.selectedUserEmail = u.email;

    this.selectedUserRole = u.userRole;//.toString()
    // addedUserName
    // addedEmail
    // uRoleSelected
    setTimeout(() => {
      this.viewMode = BASE_VIEW_MODE.EDIT
    },660);
  }

  async deleteUser(u: IExerciseUser) {
    if(this.inputDisabled) { return }
    console.log(u);
    const dto = {
      exerciseId: this.exerciseId,
      uid: u.userId
    }
    await this.api.apiPost('user/remove-user-from-exercise', dto)
    this.load()  
  }

  async inviteUserForEx(u: IExerciseUser) {
    if(this.inputDisabled) { return }
    console.log(u);
    const dto = {
      exerciseId: this.exerciseId,
      uid: u.userId
    }
    await this.api.apiPost('user/comfirm-user-for-exercise', dto)
    this.load()
  }

  showAllUsers() {
    this.loadUsers()
  }

  async inviteCompanyUserForEx(u: CompanyUser) {
    if(this.inputDisabled) { return }
    console.log(u);
    const dto = {
      exerciseId: this.exerciseId,
      email: u.email.trim().toLowerCase(),
      name: u.name,
      userRole: u.exerciseUserRole
    }
    console.log(dto);
    await this.api.apiPost('user/draft-user-for-exercise-by-email', dto)
    
    const usrs = await this.api.apiGetP('user/exercise-user/'+ this.exerciseId)
    this.exerciseUser = usrs as  IExerciseUser[]
    
    setTimeout(()=>{
      this.fixUsers()  
    }, 250)
    
    // this.load()
    // 
  }
  async loadUsers() {
    if (this.viewMode == BASE_VIEW_MODE.SHOWALL) {
      this.viewMode = BASE_VIEW_MODE.OVERVIEW;
      return;
    }
    this.viewMode = BASE_VIEW_MODE.SHOWALL;
    if (!this.storage.currentExer || !this.storage.currentExer.companyId) { return }
    const comId = this.storage.currentExer.companyId;
    
    const usrs = await this.api.apiGetP('user/company-user/'+ comId);
    console.log(usrs);
    this.companyUsers = usrs as CompanyUser[]
    this.fixUsers()
  }
  private fixUsers() {
    for(var i = 0; i < this.companyUsers.length; i ++) {
      const exUser = this.exerciseUser.find(item => item.userId == this.companyUsers[i].userId);
      if (exUser) {
        this.companyUsers[i].exerciseUserRole = exUser.userRole;
        this.companyUsers[i].userState = exUser.userState;
      }
    }
  } 
}
