import { Component, inject, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ExerciseUserOverlayMode } from 'src/app/model/enums';
import { ExerciseUserRole, IExerciseUser } from 'src/app/model/exercise.model';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-exercise-user-overlay',
  templateUrl: './exercise-user-overlay.component.html',
  styleUrls: ['./exercise-user-overlay.component.scss']
})
export class ExerciseUserOverlayComponent implements OnInit {
  private api = inject(ApiService);
  // public storage = inject(StorageService);
  olData: ExerciseUserOverlayData | undefined;
  uRoleSelected: ExerciseUserRole | undefined
  exerciseUserRoles: ExerciseUserRole[] = [
    ExerciseUserRole.ADMIN,
    ExerciseUserRole.EXCON,
    ExerciseUserRole.OBSERVER,
    ExerciseUserRole.TRAINER,
  ]
  sendInvite = false
    addedEmail = ''
  addedUserName = ''
  constructor(
    public dialogRef: MatDialogRef<ExerciseUserOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ExerciseUserOverlayData) {
      this.olData = data;
    }

  ngOnInit(): void {
  
  }
  close() {
    this.dialogRef.close()
  }
  get userNameError(): boolean {
    if(this.addedUserName.length > 4) {
      return false
    }
    return true
  }

  get emailError(): boolean {
    if(this.addedEmail.length > 4 && this.addedEmail.includes('@') && this.addedEmail.includes('.')) {
      return false
    }
    return true
  }
  
  get saveBtnDisabled(): boolean {
    if(!this.olData || !this.uRoleSelected || this.emailError || this.userNameError) {
      return true
    } else {
      return false
    }
  }
  get inputDisabled(): boolean {
    
    if(!this.olData || this.olData.inputDisabled) {
      return true
    } else {
      return false
    }
  }
  onURoleChanged(e:any) {
    console.log(e);
    if(!this.olData) { return }
    if(this.olData.inputDisabled) { return }
    // if (this.viewMode === BASE_VIEW_MODE.ADD) {
    //   this.uRoleSelected = e.target.value;
    // } else if (this.viewMode === BASE_VIEW_MODE.EDIT) {
    //   this.selectedUserRole = e.value;//.toString()
    // }
    
  }
  async onSaveUser() {
    if(!this.olData) { return }
    //return
    
    if(!this.emailError && !this.userNameError) {
      const dto = {
        exerciseId: this.olData.exerciseId,
        email: this.addedEmail.trim().toLowerCase(),
        name: this.addedUserName,
        userRole: this.uRoleSelected
      }
      console.log(dto);
      await this.api.apiPost('user/draft-user-for-exercise-by-email', dto)
      // this.onCloseWithReload.emit()
      
    
    }
    
  }
}


export type ExerciseUserOverlayData = {
  user:IExerciseUser;
  mode: ExerciseUserOverlayMode;
  inputDisabled: boolean;
  exerciseId: string

}

