<!-- CONTENT -->
<div class="exercise-content-col" #_exercise_content_col>
  <div *ngIf="exercise && !ui.isMapVisible && showUI">
    <app-timeline [exercise]="exercise"></app-timeline>
  </div>
  <app-exercise-map-view *ngIf="exercise && ui.isMapVisible"></app-exercise-map-view>
</div>

<!-- TOOLBAR -->
<div class="exercise-toolbar-col" #_exercise_toolbar_col *ngIf='!ui.isMainToolbarOut'>
  <div class="container-fluid p0 mt1">
    <div class="container-fluid p0 "
      *ngIf="_currentViewMode === viewModes.MAP_VIEW || _currentViewMode === viewModes.TIMELINE_VIEW && actionsToolbarVisible"
      #injectToolBar style="overflow: hidden;">
      <app-injects-toolbar></app-injects-toolbar>
    </div>
  </div>
</div>

<div class="time-toolbar" [class.timeToolbarLeftIn]="!ui.isMainToolbarOut"
  [class.timeToolbarLeft]="ui.isMainToolbarOut">
  <div class="d-flex w100 h100" *ngIf='exercise != null && !isLoading'>
    <div class="p-0 ">
      <div class="  " style="width: 140px; padding-left: 10px!important;">
        <img src="../../../assets/logo4.svg" alt="">
      </div>
    </div>
    <div class="p-1 tlr">
      <div class="tlr" style="width: 250px!important;">

        <dashboard-nav-bar [leftAlign]="true" (onNavEvent)="onNavEvent($event)"></dashboard-nav-bar>
      </div>
    </div>
    <div class="p-0 w100 d-flex flex-column">

      <div class="p-0   mt2 mb2 d-flex w100 headerWrap" *ngIf="exercise" #headerWrap style="flex-wrap:  wrap;">

        <div class="d-flex p0 h40 align-items-center tt-border pl3 pr3" [class.dark]="ui.darkMode" #exerciseName>
          <div class="p-0 exerTitle" (click)="textEditOverlay('exercisename')"  [class.bgWhite]="!ui.darkMode">
            {{exercise.name}}
          </div>
        </div>

        <div class="p-0 h40 mr3  d-flex tt-border  align-items-center  pr3 pl3" #exerciseStatus
          [class.dark]="ui.darkMode">
          <div class="p-0 h30 pt5 pl5 bgDark uc fs14 font-darker " style="width: 75px;" [class.bgWhite]="!ui.darkMode">
            Status:</div>
          <div class="p-0 h30 tt-box-sm tlc pl10 pr10"  [class.dark]="ui.darkMode"> <span>{{exercise.status}} <span *ngIf="exercise.isDeBoarded"
                class="pl10 " style="color: #D32F2F;">DEBOARDED</span></span></div>
        </div>

        <app-dashboard-user #exerciseUserRef [exercise]="exercise" (signOutTapped)="signOutTapped()"
          (routeToAdmin)="routeToAdmin()"></app-dashboard-user>

        <app-dashboard-role #exerciseRoleRef [exercise]="exercise"></app-dashboard-role>

        <div class="break" *ngIf="showBreak"></div>

        <app-dashboard-time title="current utc:" [times]="currentTimeArray" [width]=120></app-dashboard-time>

        <app-dashboard-time *ngIf="showCurrentLocal" title="current local:" [times]="currentLocalTimeArray"
          [width]=140></app-dashboard-time>

      </div>
    </div>
  </div>

</div>

<div class="sidBarToggler d-flex  align-items-center jusify-content-center h100">
  <div class="sibarBarToggleAniBar">
    <div class="sidnav-toggle" (click)='toggleMainToolbar()' [class.sidnav-toggle_active]='ui.isMainToolbarOut'>
      <svg xmlns="http://www.w3.org/2000/svg" class="icon" *ngIf='!ui.isMainToolbarOut' height="24" viewBox="0 0 24 24"
        width="24">
        <path d="M0 0h24v24H0V0z" fill="none" />
        <path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z" />
      </svg>
      <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24" class="icon"
        *ngIf='ui.isMainToolbarOut'>
        <path d="M0 0h24v24H0z" fill="none" />
        <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z" />
      </svg>
    </div>
  </div>
</div>

<div class="notification d-flex flex-column " #notification role="alert" data-bs-theme="dark">

  <!-- alert alert-danger -->

  <!-- <div class="" >
    A simple danger alert—check it out!
  </div> -->
  <div class="p-0">

    <div class="d-flex">
      <div class="p-2 flex-fill userName pb0 ">
        {{currentChatNot?.userName}}
        <!-- <span *ngIf="currentChatNot">
        </span> -->

      </div>
      <div class="p-2 ml-auto pb0 ">
        <mat-icon class='dxfx_icon_btn_mini' (click)='hideNotification()'>close</mat-icon>
      </div>
    </div>
  </div>

  <div class="p-0 d-flex">
    <div class="p-2 msg pb0 pt0">
      {{currentChatNot?.message}}
      <!-- <span *ngIf="currentChatNot">
      </span> -->
    </div>

  </div>

</div>