
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container" >
     
  <div class="d-flex flex-column">
    <!-- <div class="p-2">
      {{uRoleSelected}}
    </div> -->
    <div class="p-2">
      <div class="d-flex">
        
        <div class="p-2" style="width: 50%;">
          <mat-form-field appearance="outline" class="textfield100 select-form-field pb0">
            <mat-select [(value)]='uRoleSelected'  (selectionChange)='onURoleChanged($event)' 
            class="font-main">
            <mat-option >
              
            </mat-option>
              <mat-option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
                {{uRole}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <select [value]='uRoleSelected'  class="font-dark select-cg"  (change)="onURoleChanged($event)" [disabled]="inputDisabled">
            <option *ngFor="let uRole of exerciseUserRoles" [value]="uRole">
              {{uRole}}
            </option>
          </select> -->
          
        </div>
        <div class="p-2" style="width: 50%;">
          <div class="formGroup ">
            <mat-checkbox [(ngModel)]="sendInvite">invite</mat-checkbox>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2" >
      <div class="d-flex">
        
        <div class="p-2" style="width: 50%;">
          <div class="formGroup pb20">
            <input type="text" class="formControl" placeholder="Name"   [(ngModel)]="addedUserName"  [class.formControlError]="userNameError" [disabled]="inputDisabled">
          </div>
        </div>
        <div class="p-2" style="width: 50%;">
          <div class="formGroup pb20">
            <input type="text" class="formControl" placeholder="Email"  [(ngModel)]="addedEmail" [class.formControlError]="emailError" [disabled]="inputDisabled">
          </div>
        </div>
      </div>
    </div>
    <div class="p-2">
      <ect-color-btn title="Save" icon="save" color="primary" [disabled]="saveBtnDisabled || inputDisabled" (clicked)="onSaveUser()" ></ect-color-btn>
    </div>
  </div>
 

</div>